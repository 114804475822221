import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {AppBlockComponent} from './pages/app-block/app-block.component';
import {PatientCheckComponent} from './pages/patient-check/patient-check.component';
import {AdminAccessGuard} from './guards/admin-access.guard';
import {DemographicsComponent} from './pages/demographics/demographics.component';
import {PatientSetGuard} from './guards/patient-set.guard';
import {ROUTE_TYPE_APP_BLOCK, ROUTE_TYPE_AUTH, ROUTE_TYPE_FORM, ROUTE_TYPE_PATIENT_CHECK} from './config/constants';
import {MedicationsPageComponent} from './pages/medications/medications.component';
import {OcularHistoryPageComponent} from './pages/ocular/ocular.component';
import {MedicalHistoryPageComponent} from './pages/medical-history/medical-history.component';
import {MedicationsAllergiesPageComponent} from './pages/medications-allergies/medications-allergies.component';
import {FamilyHistoryComponent} from './pages/family-history/family-history.component';
import {SocialHistoryComponent} from './pages/social-history/social-history.component';
import {AdditionalHistoryComponent} from './pages/additional-history/additional-history.component';
import {ConsentAndSignaturePageComponent} from './pages/consent/consent.component';
import {AppBlockGuard} from './guards/app-block.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: null,
      type: ROUTE_TYPE_AUTH
    }
  },
  {
    path: 'patient-check',
    component: PatientCheckComponent,
    canActivate: [ AdminAccessGuard ],
    data: {
      title: 'Patient Check in',
      type: ROUTE_TYPE_PATIENT_CHECK
    }
  },
  {
    path: 'demographics',
    component: DemographicsComponent,
    canActivate: [ PatientSetGuard ],
    data: {
      title: 'Demographics',
      type: ROUTE_TYPE_FORM,
      formPageIndex: 1
    }
  },
  {
    path: 'medical-history',
    component: MedicalHistoryPageComponent,
    canActivate: [ PatientSetGuard ],
    data: {
      title: 'Medical History',
      type: ROUTE_TYPE_FORM,
      formPageIndex: 2
    }
  },
  {
    path: 'ocular-history',
    component: OcularHistoryPageComponent,
    canActivate: [ PatientSetGuard ],
    data: {
      title: 'Ocular History',
      type: ROUTE_TYPE_FORM,
      formPageIndex: 3
    }
  },
  {
    path: 'medications',
    component: MedicationsPageComponent,
    canActivate: [ PatientSetGuard ],
    data: {
      title: 'Medications',
      type: ROUTE_TYPE_FORM,
      formPageIndex: 4
    }
  },
  {
    path: 'medications-allergies',
    component: MedicationsAllergiesPageComponent,
    canActivate: [ PatientSetGuard ],
    data: {
      title: 'Medication Allergies',
      type: ROUTE_TYPE_FORM,
      formPageIndex: 5
    }
  },
  {
    path: 'family-history',
    component: FamilyHistoryComponent,
    canActivate: [ PatientSetGuard ],
    data: {
      title: 'Family History',
      type: ROUTE_TYPE_FORM,
      formPageIndex: 6
    }
  },
  {
    path: 'social-history',
    component: SocialHistoryComponent,
    canActivate: [ PatientSetGuard ],
    data: {
      title: 'Social History',
      type: ROUTE_TYPE_FORM,
      formPageIndex: 7
    }
  },
  {
    path: 'additional-history',
    component: AdditionalHistoryComponent,
    canActivate: [ PatientSetGuard ],
    data: {
      title: 'Additional History',
      type: ROUTE_TYPE_FORM,
      formPageIndex: 8
    }
  },
  {
    path: 'consent',
    component: ConsentAndSignaturePageComponent,
    canActivate: [ PatientSetGuard ],
    data: {
      title: 'Consent and Signature',
      type: ROUTE_TYPE_FORM,
      formPageIndex: 9
    }
  },
  {
    path: 'app-block-login',
    component: AppBlockComponent,
    canDeactivate: [ AppBlockGuard ],
    data: {
      type: ROUTE_TYPE_APP_BLOCK
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule { }
