import {Component, OnInit, Inject, ViewEncapsulation} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'ep-family-members',
  templateUrl: './family-members.component.html',
  styleUrls: ['./family-members.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FamilyMembersComponent implements OnInit {
  public title: string;
  public selectedMembers = [];

  constructor(
    public dialogRef: MatDialogRef<FamilyMembersComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {
  }
  ngOnInit() {
    this.title = this.data.historyType;
    this.selectedMembers = this.data.members;
  }

  save() {
    this.dialogRef.close(this.selectedMembers);
  }

}
