import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

import { LoginComponent } from './login.component';
import {MatButtonModule, MatInputModule, MatSelectModule, MatSnackBarModule} from '@angular/material';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    FlexLayoutModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatSnackBarModule
  ]
})
export class LoginModule { }
