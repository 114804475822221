import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';
import { FormBuilder, FormGroup} from '@angular/forms';
import {DobAuthModalComponent} from '../../shared/modals/dob-auth/dob-auth.component';
import {AppStateInterface} from '../../interfaces/store.interface';
import {PatientDemographicsInterface, PatientInterface} from '../../interfaces/patient.interface';
import {phoneMask, dateMask} from '../../config/masks';
import { PatientService, defaultPatientDemographics, SelectionService, AppService, AuthService } from '../../services';
import {DemographicsService} from './demographics.service';
import {NotificationService} from '../../services/notification.service';
import * as moment from 'moment';
import {NavigationEventInterface} from '../../interfaces/app.interface';

@Component({
  selector: 'ep-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemographicsComponent implements OnInit, OnDestroy, AfterViewInit {
  public patient$: Observable<PatientInterface>;
  public patient: PatientDemographicsInterface | any;
  public patientGroup: FormGroup;
  public phoneMask;
  public dateMask;
  private footerNavSubscriber: Subscription;
  private isStateUpdating = false;

  constructor (
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private patientService: PatientService,
    private appService: AppService,
    public selectionService: SelectionService,
    private authService: AuthService,
    public demographicsService: DemographicsService,
    private notificationService: NotificationService
  ) {
    this.patient = defaultPatientDemographics;
    this.patient$ = store.pipe(select('patient'));
  }

  get zipControl() {
    return this.patientGroup.get('zip');
  }

  get stateControl() {
    return this.patientGroup.get('state');
  }

  get cityControl() {
    return this.patientGroup.get('city');
  }

  ngOnInit() {
    this.phoneMask = phoneMask;
    this.dateMask = dateMask;
    this.authService.isAdmin = false;
    this.createForm();
    this.footerNavigationSubscribe();
  }

  ngOnDestroy() {
    this.footerNavSubscriber.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.route.snapshot.params.isBack) {
        this.openDobAuthModal();
      } else {
        this.getDemographicsData();
      }
    }, 100);
  }

  public onDobChange(date) {
    this.patient.dateOfBirth = date;
  }

  public stateSelected(event) {
    this.patient.state = event.option.value;
    this.patient.city = '';

    this.stateControl.setValue(this.demographicsService.getStateNameById(this.patient.state));
    this.cityControl.setValue('');
    this.getCityList();
  }

  public citySelected(event) {
    this.patient.city = event.option.value;
  }

  public createForm() {
    this.patientGroup = this.demographicsService.getConstructedForm();

    this.zipControl.valueChanges.subscribe((zip) => {

      if (this.zipControl.dirty && zip && zip.toString().length === 5 && !isNaN(zip)) {
        this.patient.zip = zip;

        this.updateStateCity(zip);
      }
    });
  }

  private openDobAuthModal() {
    const dialog = this.dialog.open(DobAuthModalComponent, {
      disableClose: true
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.getDemographicsData();
      } else {
        this.router.navigate(['app-block-login']);
      }
    });
  }

  private getDemographicsData() {
    this.appService.showSpinner();

    this.patientService.getPatientDemographics()
      .subscribe(
        (patient: PatientDemographicsInterface) => {
        this.ref.markForCheck();

        this.appService.hideSpinner();

        this.patient = patient;

        this.demographicsService.updateForm(patient);
        this.getCityList();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  private footerNavigationSubscribe() {
    this.footerNavSubscriber = this.appService.footerNavigation$
      .subscribe((navigationResult: NavigationEventInterface) => {
        this.saveDemographics(navigationResult.navCb);
      });
  }

  private saveDemographics(navigationCb: Function) {
    this.patientGroup.getRawValue();

    if (this.patientGroup.invalid || !this.patient.state) {
      const errorMsgList = this.demographicsService.getFormErrorMessages(this.patient as PatientDemographicsInterface);
      if (!this.patient.state) {
        errorMsgList.push(this.demographicsService.errorMessages.state);
      }

      this.notificationService.showList(errorMsgList);

      return;
    }

    this.appService.showSpinner();

    const dataToSave = {
      ...this.patient,
      ...this.patientGroup.value,
      state: this.patient.state,
      dateOfBirth: moment(this.patient.dateOfBirth).utc(true).unix() * 1000,
      patientBirthday: moment(this.patient.dateOfBirth).format('MM/DD/YYYY')
    } as PatientDemographicsInterface;

    this.patientService.saveDemographics(dataToSave)
      .subscribe(
        (result) => {
          if (result) {

            this.appService.hideSpinner();

            navigationCb();
          }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  private updateStateCity(zip) {
    this.isStateUpdating = true;

    this.patientService.getLocationByZip(zip)
      .subscribe(
        (location: any) => {
          this.ref.markForCheck();

          this.patient.state = location.stateId;
          this.patient.city = location.city;
          this.demographicsService.cityList = location.cityList;

          this.stateControl.setValue(this.demographicsService.getStateNameById(this.patient.state));
          this.cityControl.setValue(this.patient.city);

          this.isStateUpdating = false;
        },
        err => {
          console.log(err);
        }
      );
  }

  private getCityList() {
    if (this.patient.state) {
      this.patientService.getCitySelectionList(this.patient.state)
        .subscribe(
          (cityList) => {
            this.ref.markForCheck();

            this.demographicsService.cityList = cityList;

            if (this.patient) {
              this.cityControl.setValue(this.patient.city);
            }
          },
          err => {
            console.log(err);
          }
        );
    }
  }
}
