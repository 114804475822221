import { SET_PATIENT_DATA } from './reducer.constants';
import { PatientActions } from './patient.actions';
import {
  CustomHistoryInterface,
  MedicationAndAllergyInterface,
  PatientInterface
} from '../../interfaces/patient.interface';
import {defaultMedicalHistory, defaultPatientDemographics, defaultSocialHistory} from '../../services/mock-data';

const defaultPatient: PatientInterface = {
  patientId: 0,
  scheduleId: 0,
  demographicsDTO: defaultPatientDemographics,
  medicalHistoryDTO: defaultMedicalHistory,
  familyHistory: [],
  socialHistory: defaultSocialHistory,
  customHistory: {} as CustomHistoryInterface,
  medications: {} as MedicationAndAllergyInterface,
  allergies: {} as MedicationAndAllergyInterface
};

export function patientReducer(state: PatientInterface = defaultPatient, action: PatientActions) {
  switch (action.type) {
    case SET_PATIENT_DATA:
      return {
        ...action.payload
      };

    default:
      return state;
  }
}
