import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MedicationsPageComponent} from './medications.component';
import {TextMaskModule} from 'angular2-text-mask';
import {MatButtonModule, MatCardModule, MatIconModule, MatInputModule, MatSlideToggleModule} from '@angular/material';
import {FormsModule, NgModel, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FlexLayoutModule,
    MatCardModule,
    MatSlideToggleModule,
    SharedModule,
    TextMaskModule
  ],
  declarations: [MedicationsPageComponent]
})
export class MedicationsModule { }
