import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ep-app-block',
  templateUrl: './app-block.component.html',
  styleUrls: ['./app-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppBlockComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
