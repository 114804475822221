import { Injectable } from '@angular/core';
import selectionType from './selection.constants';
import {SelectOptionInterface} from '../interfaces/selectOptions.interface';
import {ApiService} from './api.service';
import apiConstants from './api.constants';
import {Observable} from 'rxjs/Observable';
import {catchError, map} from 'rxjs/operators';
import {NotificationService} from './notification.service';

@Injectable()
export class SelectionService {
  public preferredCommunicationList$: Observable<SelectOptionInterface[]>;
  public languageList$: Observable<SelectOptionInterface[]>;
  public employmentStatusList$: Observable<SelectOptionInterface[]>;
  public ethnicityList$: Observable<SelectOptionInterface[]>;
  public raceList$: Observable<SelectOptionInterface[]>;
  public demographicsOccupationList$: Observable<SelectOptionInterface[]>;
  public demographicsSourceList$: Observable<SelectOptionInterface[]>;
  public hobbiesList$: Observable<SelectOptionInterface[]>;
  public maritalStatusList$: Observable<SelectOptionInterface[]>;
  public livingArrangementList$: Observable<SelectOptionInterface[]>;
  public alcoholUseList$: Observable<SelectOptionInterface[]>;
  public computerUseList$: Observable<SelectOptionInterface[]>;
  public narcoticsUseList$: Observable<SelectOptionInterface[]>;
  public socialHistoryOccupationList$: Observable<SelectOptionInterface[]>;
  public smokingStatusList$: Observable<SelectOptionInterface[]>;
  public prefixList: SelectOptionInterface[];
  public stateList$: Observable<SelectOptionInterface[]>;

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService
  ) {
    this.prefixList = selectionType.patientPrefixSelection;
  }

  public prepareSelectOptions(optionItem): SelectOptionInterface {
    return {
      value: optionItem.value,
      viewValue: optionItem.label
    };
  }

  public getSelections() {
    this.getSelectionsForEyepegasus();
    this.getSelectionsForMaster();
    this.getSmokingStatusList();
    this.getStateList();
    this.getSelectionsForSocialHistory();
    this.getSocialHistoryStringSelections();
  }

  public getSelectionsForSocialHistory() {
    for (const option of selectionType.socialHistorySelectionApiList) {
      const params = {
        key: option.apiKey
      };

      this[option.id] = this.apiService.get(apiConstants.GET_SELECTIONS_FOR_MASTER, params)
        .pipe(
          map((list) => {
            return list.map((optionItem) => this.prepareSelectOptions(optionItem));
          }),
          catchError((err, caught) => {
            this.notificationService.show(err);

            return Observable.of([]);
          })
        );
    }
  }

  public getSocialHistoryStringSelections() {
    for (const option of selectionType.socialHistorySelectionInStringsApiList) {
      const params = {
        key: option.apiKey
      };

      this[option.id] = this.apiService.get(apiConstants.GET_SOCIAL_HISTORY_SELECTIONS, params)
        .pipe(
          map((list) => {
            return list.map((optionItem) => this.prepareSelectOptions(optionItem));
          }),
          catchError((err, caught) => {
            this.notificationService.show(err);

            return Observable.of([]);
          })
        );
    }
  }

  private getSelectionsForEyepegasus() {
    for (const option of selectionType.eyepegagusSelectionApiList) {
      const params = {
        key: option.apiKey
      };

      this[option.id] = this.apiService.get(apiConstants.GET_SELECTIONS_FOR_EYEPEGASUS, params)
        .pipe(
          map((list) => {
            return list.map((optionItem) => this.prepareSelectOptions(optionItem));
          }),
          catchError((err, caught) => {
            this.notificationService.show(err);

            return Observable.of([]);
          })
        );
    }
  }

  private getSelectionsForMaster() {
    for (const option of selectionType.masterSelectionApiList) {
      const params = {
        key: option.apiKey
      };

      this[option.id] = this.apiService.get(apiConstants.GET_SELECTIONS_FOR_MASTER, params)
        .pipe(
          map((list) => {
            return list.map((optionItem) => this.prepareSelectOptions(optionItem));
          }),
          catchError((err, caught) => {
            this.notificationService.show(err);

            return Observable.of([]);
          })
        );
    }
  }

  private getSmokingStatusList() {
    this.smokingStatusList$ = this.apiService.get(apiConstants.GET_SMOKING_SELECTIONS_LIST)
      .pipe(
        map((list) => {
          return list.map((optionItem) => this.prepareSelectOptions(optionItem));
        }),
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  private getStateList() {
    this.stateList$ = this.apiService.get(apiConstants.GET_STATE_SELECTIONS_LIST)
      .pipe(
        map((list) => {
          list.splice(list.find(listItem => listItem.value === 0), 1);

          return list;
        }),
        map((list) => {
          return list.map((optionItem) => this.prepareSelectOptions(optionItem));
        }),
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }
}
