// export const GET_PREFERRED_CONTACT_LIST = '/patients/preferredContactList';
// export const GET_REFERRAL_SOURCE = '/patients/referralSource';
// export const GET_LANGUAGE = '/patients/language';
// export const GET_WORK_STATUS = '/patients/workStatus';
// export const GET_EMPLOYER = 'patients/employer';
// export const GET_RACE = '/patients/race';
// export const GET_ETHNICITY = '/patients/ethnicity';
// export const GET_PATIENT_DEMOGRAPHICS = '/patient/id/demographics';
// export const GET_PATIENT_MEDICAL_HISTORY = '/patient/id/medicalHistory';
// export const GET_PATIENT_SURGERIES = '/patient/id/surgeries';
// export const GET_PATIENT_INJURIES = '/patient/id/injuries';

import {AuthInterface} from '../interfaces/auth.interface';

class ApiConstants {
  private _base_url = 'https://live.eyepegasus.com/epm-mobile-web-service-hook/api/secure/jsonws/medicalhistory';
  private _authToken = localStorage.getItem('auth');

  public GET_PATIENT_DATA = '/get-patient-demographics-details';
  public POST_LOGIN = '/public-login-for-patient-check-in';
  public GET_WORKFLOW_LIST = '/get-all-patient-workflow-list';
  public GET_SELECTIONS_FOR_MASTER = '/get-select-item-for-list-type-master';
  public GET_SELECTIONS_FOR_EYEPEGASUS = '/get-select-item-for-eyepegasus-list-type';
  public GET_SMOKING_SELECTIONS_LIST = '/get-smoking-status-list';
  public GET_STATE_SELECTIONS_LIST = '/populate-state';
  public GET_DEMOGRAPHICS = '/get-demographics-details';
  public GET_MEDICAL_HISTORY = '/get-patient-medical-history-details';
  public GET_FAMILY_HISTORY = '/get-family-history-data';
  public GET_SOCIAL_HISTORY = '/get-social-history-data';
  public GET_CITIES_BY_STATE = '/populate-cities';
  public GET_LOCATION_BY_ZIP = '/get-state-from-zip';
  public POST_DEMOGRAPHICS = '/save-patient-demographics-data';
  public POST_MEDICAL_HISTORY = '/save-patient-medical-history-details';
  public POST_FAMILY_HISTORY = '/save-family-history-data';
  public GET_PATIENT_MEDICATIONS = '/get-medications';
  public GET_PATIENT_ALLERGIES = '/get-allergies';
  public POST_PATIENT_MEDICATIONS = '/save-medications-or-allergies-value';
  public POST_PATIENT_ALLERGIES = '/save-medications-or-allergies-value';
  public GET_PATIENT_OCULAR_HISTORY = '/get-patient-ocular-history';
  public GET_SOCIAL_HISTORY_SELECTIONS = '/get-select-item-for-social-history';
  public POST_SOCIAL_HISTORY = '/save-social-history-data';
  public POST_PATIENT_OCULAR_HISTORY = '/save-ocular-history-detail';
  public GET_PATIENT_CUSTOM_HISTORY = '/get-custom-histoy-data';
  public POST_PATIENT_CUSTOM_HISTORY = '/save-custom-histoy-data';
  public POST_VERIFY_BIRTHDAY = '/verify-patient-birthday';
  public GET_VERIFY_MANAGER_CODE = '/verify-manager-code';
  public GET_CONSENT_FORM = '/get-forms';
  public POST_CONSENT_FORM = '/save-consent-forms';



  private patientId = '4';

  get BASE_URL() {
    return this._base_url;
  }

  get GET_PATIENT_DEMOGRAPHICS() {
    return `/patient/${this.patientId}/demographics`;
  }
  get GET_PATIENT_MEDICAL_HISTORY() {
    return `/patient/${this.patientId}/medicalHistory`;
  }
  get GET_PATIENT_SURGERIES() {
    return `/patient/${this.patientId}/surgeries`;
  }
  get GET_PATIENT_INJURIES() {
    return `/patient/${this.patientId}/injuries`;
  }

  set server(server: string) {
    this._base_url = server + '/epm-mobile-web-service-hook/api/secure/jsonws/medicalhistory';
  }

  set authToken(token: string) {
    this._authToken = token;

    localStorage.setItem('auth', token);
  }

  get authToken() {
    return this._authToken;
  }

  public setPatientId(id: string) {
    this.patientId = id;
  }
}

const apiConstants = new ApiConstants();

export default apiConstants;
