import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {AppStateInterface} from '../../interfaces/store.interface';
import {Observable} from 'rxjs/Observable';
import {PatientInterface} from '../../interfaces/patient.interface';

@Component({
  selector: 'ep-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  public patient$: Observable<PatientInterface>;

  @Input()
  public title: string;
  @Input()
  public pageType: string;

  constructor(
    private router: Router,
    private store: Store<AppStateInterface>
  ) {
    this.patient$ = store.pipe(select('patient'));
  }

  ngOnInit() {
  }

  public logout() {
    this.router.navigate(['login']);
  }

  public finishLater() {
    this.router.navigate(['app-block-login']);
  }
}
