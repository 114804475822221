import {EventEmitter, Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {NavigationEventInterface} from '../interfaces/app.interface';

@Injectable()
export class AppService {
  public footerNavigation$: EventEmitter<NavigationEventInterface> = new EventEmitter<NavigationEventInterface>();
  public onFinish: Function;
  public updateRequired = new EventEmitter();
  public spinnerStatusChanged: EventEmitter<boolean> = new EventEmitter();
  private _oldMedications: number[];
  private _oldAllergies: number[];

  get oldMedications() {
    return [...this._oldMedications];
  }
  get oldAllergies() {
    return [...this._oldAllergies];
  }
  set oldMedications(createdList: number[]) {
    if (!this._oldMedications.length) {
      this._oldMedications = createdList;
    }
  }
  set oldAllergies(createdList: number[]) {
    if (!this._oldAllergies.length) {
      this._oldAllergies = createdList;
    }
  }

  constructor(
  ) {
    this._oldMedications = [];
    this._oldAllergies = [];
  }

  public footerNavigate(navCb: Function, type: string) {
    if (type === 'finish' && this.onFinish) {
      if (!this.onFinish.call(this, navCb)) {
        return;
      }
    }
    this.footerNavigation$.emit({navCb, type});
  }

  public showSpinner() {
    this.spinnerStatusChanged.emit(true);
  }

  public hideSpinner() {
    this.spinnerStatusChanged.emit(false);
  }

  public resetOldLists() {
    this._oldMedications = [];
    this._oldAllergies = [];
  }
}
