import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {PatientService} from '../../services/patient.service';
import {AppService} from '../../services/app.service';
import {Subscription} from 'rxjs/Subscription';
import {OcularHistoryInterface} from '../../interfaces/patient.interface';
import {defaultOcularHistory} from '../../services/mock-data';
import * as moment from 'moment';
import {NavigationEventInterface} from '../../interfaces/app.interface';

@Component({
  selector: 'ep-ocular-history-page',
  templateUrl: './ocular.component.html',
  styleUrls: ['./ocular.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OcularHistoryPageComponent implements OnInit, OnDestroy {

  private footerNavSubscriber: Subscription;
  public entity: OcularHistoryInterface;

  constructor(
    private appService: AppService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private patientService: PatientService
  ) {
    this.entity = defaultOcularHistory;
  }

  ngOnInit() {
    this.footerNavigationSubscribe();

    this.appService.showSpinner();

    this.patientService.getOcularHistory().subscribe((res) => {
      this.appService.hideSpinner();

      this.entity = res;
      this.ref.markForCheck();
    });
  }

  ngOnDestroy() {
    this.footerNavSubscriber.unsubscribe();
  }

  changeDate(date) {
    this.entity.lastVisionExamDate = date.valueOf();
  }

  saveData(navigationCb) {
    for (const item in this.entity) {
      if (typeof this.entity[item] === 'boolean') {
        this.entity[item] = this.entity[item] ? 1 : 0;
      }
    }
    this.patientService.saveOcular(this.entity)
      .subscribe(
        result => {
          if (result) {
            navigationCb();
          }
        },
        err => {
          console.log(err);
        }
      );
  }


  private footerNavigationSubscribe() {
    this.footerNavSubscriber = this.appService.footerNavigation$
      .subscribe((navigationResult: NavigationEventInterface) => {
        this.saveData(navigationResult.navCb);
      });
  }

}
