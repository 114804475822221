import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MedicalHistoryPageComponent} from './medical-history.component';
import {SharedModule} from '../../shared/shared.module';
import {MatCardModule, MatInputModule, MatSlideToggleModule, MatSnackBarModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule
  ],
  declarations: [MedicalHistoryPageComponent]
})
export class MedicalHistoryModule { }
