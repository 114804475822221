import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject} from '@angular/core';
import {MAT_DATE_LOCALE, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {PatientCheckinInterface, PatientInterface} from '../../../interfaces/patient.interface';
import { dateMask } from '../../../config/masks';
import {Observable} from 'rxjs/Observable';
import {PatientService} from '../../../services/patient.service';

import * as moment from 'moment';

@Component({
  selector: 'ep-dob-auth',
  templateUrl: './dob-auth.component.html',
  styleUrls: ['./dob-auth.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-US'}
  ],
})
export class DobAuthModalComponent implements OnInit {
  public patient: PatientCheckinInterface;
  public patient$: Observable<PatientInterface>;
  public formGroup: FormGroup;
  public dateMask;
  public dobControl: FormControl;
  public selectedDobControl: FormControl;
  public dobStarts;
  public selectedDate: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PatientCheckinInterface,
    private dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    public patientService: PatientService,
    private snackBar: MatSnackBar
  ) {
    this.dobStarts = new Date('01/01/1968');
  }

  ngOnInit() {
    this.dateMask = dateMask;

    this.constructForm();
  }

  public closeModal(result = null): void {
    this.dialogRef.close(result);
  }

  public confirm(): void {
    const dateString = moment(this.selectedDobControl.value).format('MM/DD/YYYY');
    this.patientService.verifyBirthday(dateString)
      .subscribe((res) => {
        if (res) {
          this.dialogRef.close('success');
        } else {
          this.openSnackBar('Wrong Date of birth');
        }
      });
  }

  private constructForm() {
    this.dobControl = this.formBuilder.control('');
    this.selectedDobControl = this.formBuilder.control('');

    this.formGroup = this.formBuilder.group({
      dob: this.dobControl,
      selectedDob: this.selectedDobControl
    });

    this.dobControl.valueChanges.subscribe(value => {
      if (this.selectedDobControl.pristine) {
        this.selectedDobControl.setValue(moment(value).format('MM/DD/YYYY'));
      }

      this.selectedDobControl.markAsPristine();
    });

    this.selectedDobControl.valueChanges.subscribe(value => {
      if (this.selectedDobControl.dirty) {
        this.dobControl.setValue(new Date(value));
      }
    });
  }

  private openSnackBar(msg: string) {
    this.snackBar.open(msg, null, {
      duration: 4000
    });
  }
}
