import { SET_USER } from './reducer.constants';
import { UserActions } from './user.actions';
import {UserInterface} from '../../interfaces/user.interface';

const defaultUser: UserInterface = {
  adressId: null,
  cellPhoneId: null,
  emailId: null,
  homePhoneId: null,
  organizationId: '',
  userId: '',
  workPhoneId: null
};

export function userReducer(state: UserInterface = defaultUser, action: UserActions) {
  switch (action.type) {
    case SET_USER:
      return {
        ...action.payload
      };

    default:
      return state;
  }
}
