import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter} from '@angular/core';
import {PatientService} from '../../../services/patient.service';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'ep-admin-code',
  templateUrl: './admin-code.component.html',
  styleUrls: ['./admin-code.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminCodeComponent implements OnInit {
  public code: string;

  @Output()
  public onUnlock = new EventEmitter();

  constructor() {
    this.code = '';
  }

  ngOnInit() {
  }

  public addSymbol(symbol: string) {
    this.code += symbol;
  }

  public removeSymbol() {
    this.code = this.code.slice(0, -1);
  }

  public unlock() {
    this.onUnlock.emit(this.code);
  }
}
