import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule, MatCardModule, MatCheckboxModule, MatInputModule, MatSidenavModule, MatSnackBarModule} from '@angular/material';

import { AppBlockComponent } from './app-block.component';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  declarations: [
    AppBlockComponent
  ],
  imports: [
    FlexLayoutModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    SharedModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatSnackBarModule
  ]
})
export class AppBlockModule { }
