import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PatientCheckComponent} from './patient-check.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatCardModule
  ],
  declarations: [
    PatientCheckComponent
  ]
})
export class PatientCheckModule { }
