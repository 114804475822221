import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class NotificationService {
  public notificationEvent: EventEmitter<string>;
  public notificationListEvent: EventEmitter<string[]>;

  constructor() {
    this.notificationEvent = new EventEmitter<string>();
    this.notificationListEvent = new EventEmitter<string[]>();
  }

  public show(msg: string) {
    this.notificationEvent.emit(msg);
  }

  public showList(list: string[]) {
    this.notificationListEvent.emit(list);
  }
}
