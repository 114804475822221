import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyHistoryComponent } from './family-history.component';
import {MatButtonModule, MatCardModule, MatDialogModule, MatSlideToggleModule} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FamilyMembersComponent} from './family-members-dialog';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    FormsModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatButtonModule,
    FlexLayoutModule
  ],
  declarations: [
    FamilyHistoryComponent,
    FamilyMembersComponent
  ],
  entryComponents: [
    FamilyMembersComponent
  ]
})
export class FamilyHistoryModule { }
