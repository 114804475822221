import {
  ViewEncapsulation, Component, Input, Output, OnInit, ChangeDetectionStrategy, EventEmitter, ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { dateMask } from '../../../config/masks';
import * as moment from 'moment';
import {Subscription} from 'rxjs/Subscription';
import {MatDatepickerInputEvent} from '@angular/material';

@Component({
  selector: 'ep-date-input',
  templateUrl: './ep-date-input.template.html',
  styleUrls: ['./ep-date-input.styles.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EPDateInputComponent implements OnInit, OnDestroy {
  @Input() set date(value) {
    if (value === 0) {
      this.selectedDate = null;
    } else if (typeof value === 'number') {
      this.selectedDate = new Date(value);
    } else {
      this.selectedDate = value;
    }

    if (this.dateControl) {
      const date = moment(value).format('MM/DD/YYYY');

      this.dateISO = value;
      this.dateString = value ? date : '';

      this.dateControl.setValue(this.dateString);
    }
  }
  @Input() set disabled(value: boolean) {
    if (value) {
      this.datePickerDisabled = value;

      this.dateControl.disable();
    } else {
      this.dateControl.enable();
    }
  }
  selectedDate: Date;
  @Input()
  public placeholder: string;
  @Input()
  public min: string;
  @Input()
  public max: string;
  @Input()
  public required: boolean;
  @Input()
  public dpStartsDate: Date;

  @Output()
  private onChange = new EventEmitter();
  @Output()
  private onInputClick = new EventEmitter();

  public dateMask;
  public dateString: string;
  public formGroup: FormGroup;
  public dateISO: Date;
  public datePickerDisabled: boolean;
  private dateControl: FormControl;
  private dateSubscriber: Subscription;

  private static isInputDateValid(dateString) {
    let result = false;

    if (dateString && dateString.length === 10 && dateString.indexOf('_') < 0) {
      result = moment(dateString).isValid();
    }

    return result;
  }

  constructor(
    private ref: ChangeDetectorRef,
    private formBuilder: FormBuilder
  ) {
    this.constructForm();
  }

  public ngOnInit() {
    this.dateString = this.selectedDate ? moment(this.selectedDate).format('MM/DD/YYYY') : '';
    this.dateISO = this.selectedDate || null;
    this.dateMask = dateMask;

    this.dateChangeSubscribe();
  }

  public ngOnDestroy() {
  }

  public constructForm() {
    this.dateControl = this.formBuilder.control(this.dateString);

    this.formGroup = this.formBuilder.group({
      date: this.dateControl
    });
  }

  public setDate(date) {
    this.ref.markForCheck();
    this.dateString = date;

    this.onChange.emit(new Date(date));
  }

  public dateChangeByDatepicker(pickerEvent) {
    const date = moment(pickerEvent.value).format('MM/DD/YYYY');
    this.dateISO = pickerEvent.value;
    this.setDate(date);

    this.dateSubscriber.unsubscribe();

    this.dateControl.setValue(date);

    this.dateChangeSubscribe();
  }

  public inputClick() {
    this.onInputClick.emit(this.selectedDate ? this.selectedDate.getTime() : 0);
  }

  private dateChangeSubscribe() {
    this.dateSubscriber = this.dateControl.valueChanges.subscribe((value) => {
      if (EPDateInputComponent.isInputDateValid(value)) {
        this.dateISO = value ? new Date(value) : null;

        this.setDate(value);
      } else if (value.length === 0) {
        this.dateISO = new Date();

        this.onChange.emit(null);
      }
    });
  }
}
