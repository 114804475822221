import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import {DobAuthModalComponent} from './modals/dob-auth/dob-auth.component';
import {
  MatButtonModule,
  MatInputModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCardModule,
  MatSnackBarModule
} from '@angular/material';
import {EPDateInputComponent} from './components/ep-date-input';
import { AppBlockComponent } from './modals/app-block/app-block.component';
import { AdminCodeComponent } from './components/admin-code/admin-code.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { ListSnackbarComponent } from './components/list-snackbar/list-snackbar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatDatepickerModule,
    MatCardModule,
    MatNativeDateModule,
    MatSnackBarModule,
    FlexLayoutModule
  ],
  declarations: [
    DobAuthModalComponent,
    EPDateInputComponent,
    AppBlockComponent,
    AdminCodeComponent,
    ListSnackbarComponent
  ],
  exports: [
    DobAuthModalComponent,
    EPDateInputComponent,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    AdminCodeComponent
  ],
  entryComponents: [
    DobAuthModalComponent,
    AppBlockComponent,
    ListSnackbarComponent
  ]
})
export class SharedModule { }
