import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {fadeInAnimation} from '../../route.animation';
import {Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {LoginService} from '../../services/login.service';
import {UserInterface} from '../../interfaces/user.interface';
import {AuthInterface} from '../../interfaces/auth.interface';
import apiConstants from '../../services/api.constants';
import {AuthService} from '../../services/auth.service';
import {AppService} from '../../services';
import {NotificationService} from '../../services/notification.service';

const default_server = 'https://live.eyepegasus.com';
apiConstants.server = default_server;

@Component({
  selector: 'ep-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ fadeInAnimation ]
})
export class LoginComponent implements OnInit {
  public formGroup: FormGroup;

  constructor(
    private router: Router,
    private ref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private authService: AuthService,
    private appService: AppService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    this.constructForm();
  }

  public login() {
    const auth: AuthInterface = {
      username: this.formGroup.value.username,
      password: this.formGroup.value.password,
      formDate: new Date().getTime().toString()
    };

    this.appService.showSpinner();

    this.loginService.login(auth)
      .toPromise()
      .then((user: UserInterface) => {
        if (user) {
          this.appService.hideSpinner();
          this.authService.isAdmin = true;
          this.router.navigate(['patient-check']);
        }
      }).catch((err) => {
          this.notificationService.show('Wrong login or password');
      });
  }

  private constructForm() {
    const serverControl = this.formBuilder.control(default_server);

    this.formGroup = this.formBuilder.group({
      username: this.formBuilder.control(''),
      password: this.formBuilder.control(''),
      server: serverControl
    });

    serverControl.valueChanges.subscribe((server) => {
      apiConstants.server = server;
    });
  }

}
