import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {NAV_NEXT, NAV_PREV} from '../../config/constants';
import {Router} from '@angular/router';
import {AppService} from '../../services/app.service';
import {FamilyHistoryIllnessInterface, FamilyHistoryInterface} from '../../interfaces/patient.interface';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {FamilyMembersComponent} from './family-members-dialog';
import {PatientService} from '../../services/patient.service';
import {defaultFamilyHistory} from '../../services/mock-data';
import {SelectionService} from '../../services/selection.service';
import {NavigationEventInterface} from '../../interfaces/app.interface';

@Component({
  selector: 'ep-family-history',
  templateUrl: './family-history.component.html',
  styleUrls: ['./family-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FamilyHistoryComponent implements OnInit, OnDestroy {
  public familyHistory: FamilyHistoryInterface;
  private footerNavSubscriber: Subscription;

  private static prepareMembersDialogConfig(illness: FamilyHistoryIllnessInterface): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      members: illness.members,
      historyType: illness.historyType
    };

    dialogConfig.width = '60vw';
    dialogConfig.maxWidth = '60vw';


    return dialogConfig;
  }

  private static prepareFamilyListToSave(illnessList: FamilyHistoryIllnessInterface[]) {
    return illnessList.map((illness) => {
      const preparedIllness = {
        ...illness
      };
      delete preparedIllness.members;

      for (const member of illness.members) {
        preparedIllness[member.id] = member.isSelected ? 1 : 0;
      }

      return preparedIllness;
    });
  }

  constructor(
    private appService: AppService,
    private router: Router,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private patientService: PatientService,
    private selectionService: SelectionService
  ) {
    this.familyHistory = defaultFamilyHistory;
  }

  ngOnInit() {
    this.getFamilyHistory();
    this.footerNavigationSubscribe();
  }

  ngOnDestroy() {
    this.footerNavSubscriber.unsubscribe();
  }

  public getNeatMembersString(members) {
    return members
      .filter(m => m.isSelected)
      .map(m => m.title)
      .join(', ');
  }

  public openMembersDialog(illness: FamilyHistoryIllnessInterface) {
    const dialogConfig = FamilyHistoryComponent.prepareMembersDialogConfig(illness);
    const dialogRef = this.dialog.open(FamilyMembersComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((results) => {
      this.ref.markForCheck();

      illness.members = results;
      this.familyHistory.familyLists = [...this.familyHistory.familyLists];
    });
  }

  public saveHistory(navigationCb: Function) {
    const preparedHistory = this.prepareFamilyHistoryToSave();
    this.appService.showSpinner();

    this.patientService.saveFamilyHistory(preparedHistory)
      .subscribe((result) => {
        this.appService.hideSpinner();
        if (result) {
          navigationCb();
        }
      });
  }

  private prepareFamilyHistoryToSave() {
    return {
      patientId: this.familyHistory.patientId,
      scheduleId: this.familyHistory.scheduleId,
      lastUpdatedDate: this.familyHistory.lastUpdatedDate,
      familyLists: FamilyHistoryComponent.prepareFamilyListToSave(this.familyHistory.familyLists)
    };
  }

  private getFamilyHistory() {
    this.appService.showSpinner();

    this.patientService.getFamilyHistory()
      .subscribe((familyHistory) => {
        this.appService.hideSpinner();

        this.ref.markForCheck();

        this.familyHistory = familyHistory;
      });
  }

  private footerNavigationSubscribe() {
    this.footerNavSubscriber = this.appService.footerNavigation$
      .subscribe((navigationResult: NavigationEventInterface) => {
        this.saveHistory(navigationResult.navCb);
      });
  }

}
