import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OcularHistoryPageComponent} from './ocular.component';
import {MatCardModule, MatInputModule, MatSlideToggle, MatSlideToggleModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask';
import {EPDateInputComponent} from '../../shared/components/ep-date-input';
import {SharedModule} from '../../shared/shared.module';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSlideToggleModule,
    SharedModule,
    TextMaskModule,
    FlexLayoutModule,
    MatCardModule
  ],
  declarations: [OcularHistoryPageComponent]
})
export class OcularHistoryModule { }
