import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemographicsComponent } from './demographics.component';
import {MatAutocompleteModule, MatDialogModule, MatInputModule, MatRadioModule, MatSelectModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DemographicsService} from './demographics.service';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    TextMaskModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    SharedModule
  ],
  declarations: [DemographicsComponent],
  providers: [
    DemographicsService
  ]

})
export class DemographicsModule { }
