import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionalHistoryComponent } from './additional-history.component';
import {
  MatButtonModule,
  MatCardModule,
  MatIconModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule, MatTooltipModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TextMaskModule} from 'angular2-text-mask';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import { ExtraTextEditComponent } from './extra-text-edit/extra-text-edit.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FlexLayoutModule,
    MatCardModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    SharedModule,
    TextMaskModule,
    MatTooltipModule
  ],
  declarations: [AdditionalHistoryComponent, ExtraTextEditComponent],
  entryComponents: [ExtraTextEditComponent]
})
export class AdditionalHistoryModule { }
