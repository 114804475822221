import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MedicationsAllergiesPageComponent} from './medications-allergies.component';
import {TextMaskModule} from 'angular2-text-mask';
import {MatButtonModule, MatCardModule, MatIconModule, MatInputModule, MatSelectModule, MatSlideToggleModule} from '@angular/material';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSlideToggleModule,
    SharedModule,
    TextMaskModule,
    FlexLayoutModule,
    MatCardModule,
    MatSelectModule
  ],
  declarations: [MedicationsAllergiesPageComponent]
})
export class MedicationsAllergiesModule { }
