import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RoutingModule} from './app-routing';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import {MatButtonModule, MatMenuModule, MatProgressSpinnerModule, MatSnackBarModule} from '@angular/material';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

import { environment } from '../environments/environment'; // Angular CLI environemnt

// Store
import { patientReducer, userReducer } from './store';

// Pages
import {
  LoginModule,
  AppBlockModule,
  PatientCheckModule,
  DemographicsModule,
  MedicalHistoryModule,
  AdditionalHistoryModule,
  SocialHistoryModule,
  ConsentAndSignatureModule,
  FamilyHistoryModule
} from './pages';

// Services
import { AuthService } from './services/auth.service';
import {ThemesService} from './services/themes.service';
import {LoginService} from './services/login.service';
import {AppService} from './services/app.service';

import { AppComponent } from './app.component';

// Guards
import {AdminAccessGuard} from './guards/admin-access.guard';
import {PatientSetGuard} from './guards/patient-set.guard';
import {AppBlockGuard} from './guards/app-block.guard';

import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import {SharedModule} from './shared/shared.module';
import {PatientService} from './services/patient.service';
import {ApiService} from './services/api.service';
import {OcularHistoryModule} from './pages/ocular/ocular.module';
import {MedicationsModule} from './pages/medications/medications.module';
import {MedicationsAllergiesModule} from './pages/medications-allergies/medications-allergies.module';
import {HttpClientModule} from '@angular/common/http';
import {SelectionService} from './services/selection.service';
import {NotificationService} from './services/notification.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    RoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    LoginModule,
    AppBlockModule,
    PatientCheckModule,
    DemographicsModule,
    MedicationsModule,
    MedicationsAllergiesModule,
    OcularHistoryModule,
    MedicalHistoryModule,
    AdditionalHistoryModule,
    SocialHistoryModule,
    ConsentAndSignatureModule,
    FamilyHistoryModule,

    SharedModule,

    MatButtonModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatSnackBarModule,

    FlexLayoutModule,
    StoreModule.forRoot({
      patient: patientReducer,
      user: userReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    })
  ],
  providers: [
    ThemesService,
    AuthService,
    AdminAccessGuard,
    PatientSetGuard,
    AppBlockGuard,
    LoginService,
    PatientService,
    ApiService,
    AppService,
    SelectionService,
    NotificationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
