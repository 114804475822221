import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material';

@Component({
  selector: 'ep-list-snackbar',
  templateUrl: './list-snackbar.component.html',
  styleUrls: ['./list-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListSnackbarComponent implements OnInit {
  public messageList: string[];

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: string[],
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.messageList = this.data;
  }

}
