import {EventEmitter, Injectable} from '@angular/core';
import {map, catchError} from 'rxjs/operators';
import 'rxjs/add/observable/of';
import {ApiService} from './api.service';
import {SelectOptionInterface} from '../interfaces/selectOptions.interface';
import {Observable} from 'rxjs/Observable';
import {
  ConsentDtoInterface,
  CustomHistoryFormInterface,
  FamilyHistoryIllnessInterface, FamilyHistoryInterface,
  MedicalHistoryInterface, MedicationAndAllergyDTO,
  MedicationsAndAllergiesInterface, OcularHistoryInterface, PatientCheckinInterface,
  PatientDemographicsInterface, SocialHistoryInterface,
  SurgeryInjuryInterface
} from '../interfaces/patient.interface';
import apiConstants from './api.constants';
import { FAMILY_MEMBER_LIST } from './patient.constants';
import {Store} from '@ngrx/store';
import {AppStateInterface} from '../interfaces/store.interface';
import {SelectionService} from './selection.service';
import {AuthService} from './auth.service';
import {NotificationService} from './notification.service';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

@Injectable()
export class PatientService {
  public storage = {};
  private _isFilled: boolean;
  private _selectedPatient: PatientCheckinInterface;

  private static prepareMedicalHistoryList(list): SurgeryInjuryInterface[] {
    return list.map((item): SurgeryInjuryInterface => {
      return {
        ...item,
        isDisabled: true
      } as SurgeryInjuryInterface;
    });
  }

  private static prepareFamilyHistory(historyList): FamilyHistoryIllnessInterface[] {
    return historyList.map((historyItem) => {
      const illnessItem = {
        categoryId: historyItem.categoryId,
        displayName: historyItem.displayName,
        historyId: historyItem.historyId,
        historyType: historyItem.historyType,
        id: historyItem.id,
        name: historyItem.name,
        patientId: historyItem.patientId,
        members: []
      };

      for (const member of FAMILY_MEMBER_LIST) {
        illnessItem.members.push({
          id: member.id,
          title: member.name,
          isSelected: historyItem[member.id]
        });
      }

      return illnessItem;
    });
  }

  private static prepareHobbies(hobbiesList): string[] {
    return hobbiesList.map((hobby) => hobby.id );
  }

  constructor(
    private apiService: ApiService,
    private store: Store<AppStateInterface>,
    private selectionService: SelectionService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
  }

  get isFilled() {
    return this._isFilled;
  }

  set isFilled(status: boolean) {
    this._isFilled = status;
  }

  get selectedPatient() {
    return this._selectedPatient;
  }

  set selectedPatient(patient: PatientCheckinInterface) {
    this._selectedPatient = patient;
  }

  public setData(key, data) {
    this.storage[key] = data;
  }

  public getData(key) {
    return this.storage[key];
  }

  public hasData(key) {
    return this.storage[key] || false;
  }

  public getCustomHistory(): Observable<CustomHistoryFormInterface[]> {
    const params = {
      scheduleId: this._selectedPatient.scheduleId,
      timezone
    };

    return this.apiService.get(apiConstants.GET_PATIENT_CUSTOM_HISTORY, params)
      .pipe(
        map((patientData) => {
          return patientData.customHistoryFormDTOs;
        })
      );
  }

  public saveCustomHistory(obj) {
    return this.apiService.postUrlEncoded(apiConstants.POST_PATIENT_CUSTOM_HISTORY, {
      scheduleId: this._selectedPatient.scheduleId,
      patientFormData: obj
    });
  }

  public getOcularHistory(): Observable<OcularHistoryInterface> {
    const params = {
      patientId: this._selectedPatient.patientId,
      timezone
    };
    return this.apiService.get(apiConstants.GET_PATIENT_OCULAR_HISTORY, params)
      .pipe(
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public saveOcular(ocular) {
    return this.apiService.postUrlEncoded(apiConstants.POST_PATIENT_OCULAR_HISTORY, {
      obj: ocular,
    });
  }

  public getMedicationsInfo(): Observable<MedicationsAndAllergiesInterface> {
    const params = {
      patientId: this._selectedPatient.patientId,
      timezone
    };
    return this.apiService.get(apiConstants.GET_PATIENT_MEDICATIONS, params)
      .pipe(
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public saveMedication(medication) {
    return this.apiService.postUrlEncoded(apiConstants.POST_PATIENT_MEDICATIONS, {
      obj: medication,
      isMedication: true
    });
  }

  public getAllergiesInfo(): Observable<MedicationsAndAllergiesInterface> {
    const params = {
      patientId: this._selectedPatient.patientId,
      timezone
    };
    return this.apiService.get(apiConstants.GET_PATIENT_ALLERGIES, params)
      .pipe(
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }


  public saveAllergies(medication) {
    return this.apiService.postUrlEncoded(apiConstants.POST_PATIENT_ALLERGIES, {
      obj: medication,
      isMedication: false
    });
  }

  public getPatientList(organizationId: string): Observable<PatientCheckinInterface[]> {
    return this.apiService.get(apiConstants.GET_WORKFLOW_LIST, { organizationId, timezone })
      .pipe(
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.of([]);
        })
      );
  }

  public getPatientDemographics(): Observable<PatientDemographicsInterface> {
    const params = {
      patientId: this._selectedPatient.patientId,
      scheduleId: this._selectedPatient.scheduleId,
      timezone
    };

    return this.apiService.get(apiConstants.GET_DEMOGRAPHICS, params)
      .pipe(
        map((patientData) => {
          return {
            ...patientData,
            dateOfBirth: new Date(patientData.patientBirthday || patientData.dateOfBirth)
          } as PatientDemographicsInterface;
        }),
        catchError((err) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public getLocationByZip(zip) {
    return this.apiService.get(apiConstants.GET_LOCATION_BY_ZIP, {zip})
      .pipe(
        map((location) => {
          return {
            ...location,
            cityList: location.cityList ? location.cityList.map((city) => this.selectionService.prepareSelectOptions(city)) : []
          };
        }),
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public getCitySelectionList(stateId): Observable<SelectOptionInterface[]> {
    return this.apiService.get(apiConstants.GET_CITIES_BY_STATE, {stateId})
      .pipe(
        map((cityList) => {
          return cityList.map((city) => this.selectionService.prepareSelectOptions(city));
        }),
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.of([]);
        })
      );
  }

  public saveDemographics(demographicsData: PatientDemographicsInterface) {
    const params = {
      demographicsData: JSON.stringify({
        ...demographicsData
      }),
      timezone
    };

    return this.apiService.postFormData(apiConstants.POST_DEMOGRAPHICS, params)
      .pipe(
        catchError((err) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public saveContestData(data: ConsentDtoInterface) {
    data.scheduleId = this._selectedPatient.scheduleId;
    data.patientId = this._selectedPatient.patientId;
    const params = {
      obj: JSON.stringify(data),
      timezone
    };
    return this.apiService.postFormData(apiConstants.POST_CONSENT_FORM, params);
  }

  public saveMedicalHistory(historyData: MedicalHistoryInterface) {
    return this.apiService.postUrlEncoded(apiConstants.POST_MEDICAL_HISTORY, { obj: historyData })
      .pipe(
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public saveFamilyHistory(historyData) {
    return this.apiService.postUrlEncoded(apiConstants.POST_FAMILY_HISTORY, { obj: historyData })
      .pipe(
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public getMedicalHistory(): Observable<MedicalHistoryInterface> {
    const params = {
      patientId: this._selectedPatient.patientId,
      timezone
    };
    return this.apiService.get(apiConstants.GET_MEDICAL_HISTORY, params)
      .pipe(
        map((history) => {
          return {
            ...history,
            surgeriesLists: PatientService.prepareMedicalHistoryList(history.surgeriesLists),
            injuriesLists: PatientService.prepareMedicalHistoryList(history.injuriesLists)
          } as MedicalHistoryInterface;
        }),
        catchError((err) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public getFamilyHistory(): Observable<FamilyHistoryInterface> {
    const params = {
      patientId: this._selectedPatient.patientId,
      timezone
    };

    return this.apiService.get(apiConstants.GET_FAMILY_HISTORY, params)
      .pipe(
        map((history) =>  {
          return {
            patientId: history.patientId,
            scheduleId: history.scheduleId,
            lastUpdatedDate: history.lastUpdatedDate,
            familyLists: PatientService.prepareFamilyHistory(history.familyLists)
          };
        }),
        catchError((err) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public getSocialHistory(): Observable<SocialHistoryInterface> {
    const params = {
      patientId: this._selectedPatient.patientId,
      timezone
    };

    return this.apiService.get(apiConstants.GET_SOCIAL_HISTORY, params)
      .pipe(
        map((history) => {
          const hobbyInfo = history.hobbiesLists && history.hobbiesLists.length ? history.hobbiesLists[0] : {};

          return {
            ...history,
            hobbies: PatientService.prepareHobbies(history.hobbiesLists),
            categoryId: hobbyInfo.categoryId,
            historyId: hobbyInfo.historyId,
            name: hobbyInfo.name,
            hobbiesLists: history.hobbiesLists
          } as SocialHistoryInterface;
        }),
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public saveSocialHistory(history) {
    const params = {
      scheduleId: this._selectedPatient.scheduleId,
      obj: history
    };

    return this.apiService.postUrlEncoded(apiConstants.POST_SOCIAL_HISTORY, params)
      .pipe(
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public getConsentForms(): Observable<object> {
    const orgId = this.authService.user.organizationId;
    return this.apiService.get(apiConstants.GET_CONSENT_FORM, { organizationId: orgId })
      .pipe(
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public verifyBirthday(birthday: string): Observable<any> {
    const params = {
      patientId: this._selectedPatient.patientId,
      birthday
    };

    return this.apiService.postFormData(apiConstants.POST_VERIFY_BIRTHDAY, params)
      .pipe(
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  public clearStorageData(key: string) {
    delete this.storage[key];
  }
}
