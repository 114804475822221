import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'ep-extra-text-edit',
  templateUrl: './extra-text-edit.component.html',
  styleUrls: ['./extra-text-edit.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtraTextEditComponent implements OnInit {
  public text: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<any>
  ) {
    this.text = data.text;
  }

  ngOnInit() {
  }

  public close(result = null) {
    this.dialogRef.close(result);
  }

}
