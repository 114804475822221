import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/empty';
import { map, catchError, retry } from 'rxjs/operators';
import apiConstants from './api.constants';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable()
export class ApiService {
  constructor(
    private http$: HttpClient
  ) {
  }

  public get(url: string, params = {}): Observable<any> {
    const headers = new HttpHeaders(apiConstants.authToken ? {'Authorization': apiConstants.authToken} : {});

    return this.http$.get(apiConstants.BASE_URL + url, {headers, params, observe: 'response'})
      .pipe(
        map((response: any) => {
          if (response.body && response.body.hasOwnProperty('exception')) {
            throw new Error(response.exception);
          } else {
            return response.body;
          }
        })
      );
  }

  public post(url: string, body, contentType = 'application/x-www-form-urlencoded'): Observable<any> {
    const baseUrl = apiConstants.BASE_URL;
    const headers = new HttpHeaders(apiConstants.authToken ? {'Authorization': apiConstants.authToken} : {})
      .set('Content-Type', contentType);

    return this.http$.post(baseUrl + url, body, { headers })
      .pipe(
        map((response: any) => {
          if (response && response.hasOwnProperty('exception')) {
            throw new Error(response.exception);
          } else {
            return response;
          }
        })
      );
  }

  public postUrlEncoded(url: string, params: any = {}): Observable<any> {
    const baseUrl = apiConstants.BASE_URL;
    const headers = new HttpHeaders(apiConstants.authToken ? {'Authorization': apiConstants.authToken} : {})
      .set('Content-Type', 'application/x-www-form-urlencoded');

    const chunks = [];
    for (const item in params) {
      if (params.hasOwnProperty(item)) {
        chunks.push(item + '=' + encodeURIComponent(JSON.stringify(params[item])));
      }
    }

    return this.http$.post(baseUrl + url, chunks.join('&'), { headers })
      .pipe(
        map((response: any) => {
          if (response && response.hasOwnProperty('exception')) {
            throw new Error(response.exception);
          } else {
            return response;
          }
        })
      );
  }

  public postFormData(url: string, params: any = {}): Observable<any> {
    const baseUrl = apiConstants.BASE_URL;


    const headers = new HttpHeaders(apiConstants.authToken ? {'Authorization': apiConstants.authToken} : {});
    const formData: FormData = new FormData();

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        formData.append(key, params[key]);
      }
    }

    return this.http$.post(baseUrl + url, formData, { headers })
      .pipe(
        retry(3),
        map((response: any) => {
          if (response && response.hasOwnProperty('exception')) {
            throw new Error(response.exception);
          } else {
            return response;
          }
        })
      );
  }
}
