import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {NAV_NEXT, NAV_PREV} from '../../config/constants';
import {AppService} from '../../services/app.service';
import {Subscription} from 'rxjs/Subscription';
import {PatientService} from '../../services/patient.service';
import {MedicationAndAllergyDTO, MedicationAndAllergyInterface, MedicationsAndAllergiesInterface} from '../../interfaces/patient.interface';
import {defaultMedications} from '../../services/mock-data';
import {NavigationEventInterface} from '../../interfaces/app.interface';

@Component({
  selector: 'ep-medications-page',
  templateUrl: './medications.component.html',
  styleUrls: ['./medications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MedicationsPageComponent implements OnInit, OnDestroy {

  private footerNavSubscriber: Subscription;
  public entity: MedicationsAndAllergiesInterface = defaultMedications;
  public medications: MedicationAndAllergyInterface[] = [];
  public filteredMedications: MedicationAndAllergyInterface[] = [];
  public isNoActive: boolean;

  constructor(
    private appService: AppService,
    private router: Router,
    private patientService: PatientService,
    private ref: ChangeDetectorRef
  ) {
    this.isNoActive = false;
    this.getMedications();
  }

  ngOnInit() {
    this.footerNavigationSubscribe();
  }

  ngOnDestroy() {
    this.footerNavSubscriber.unsubscribe();
  }

  private getMedications() {
    this.appService.showSpinner();

    this.patientService.getMedicationsInfo()
      .subscribe((medications) => {
        this.appService.hideSpinner();

        if (medications && medications.medicationAndAllergies) {
          this.medications = medications.medicationAndAllergies;
          this.filteredMedications = [...this.medications];

          this.appService.oldMedications = this.medications.map(med => med.id);

          for (const med of this.medications){
            if (!med.stopDate) {
              med.stopDate = null;
            }
            med.isExisting = this.appService.oldMedications.includes(med.id);
          }

          this.entity = medications;
          this.ref.markForCheck();
        }

        this.medications = [
          ...this.medications.filter(med => !med.isExisting),
          ...this.medications.filter(med => med.isExisting)
        ];
      });
  }

  public noMedicationsChanged(value) {
    this.entity.noActiveMedications = value ? 1 : 0;
  }

  public addMedication() {
    if (this.entity && this.entity.medicationAndAllergies) {
      this.medications.unshift({
        id: 0,
        name: '',
        current: true,
        startDate: (new Date()),
        stopDate: null,
        isExisting: false
      });

      this.filterMedications();
      this.entity.noActiveMedications = 0;
    }
  }

  private filterMedications() {
    this.ref.markForCheck();

    const filteredMedications = this.medications.filter(medication => medication.stopDate);
    this.filteredMedications = this.isNoActive ? filteredMedications : [...this.medications];
  }

  private saveData(navigationCb) {
    const medications: MedicationAndAllergyDTO[] = [];

    for (const entity of this.medications){
      medications.push({
        id: entity.id,
        startDate: entity.startDate ? entity.startDate.valueOf() : 0,
        stopDate: entity.stopDate ? entity.stopDate.valueOf() : 0,
        reaction: null,
        current: entity.current ? 1 : 0,
        name: entity.name
      });
    }
    this.patientService.saveMedication({
      ...this.entity,
      noActiveMedications: this.entity.noActiveMedications ? 1 : 0,
      medicationAndAllergies: medications
    }).subscribe(
      (result) => {
        if (result) {
          navigationCb();
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  private footerNavigationSubscribe() {
    this.footerNavSubscriber = this.appService.footerNavigation$
      .subscribe((navigationResult: NavigationEventInterface) => {
        this.saveData(navigationResult.navCb);
      });
  }
}
