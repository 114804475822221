import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {AppService} from '../../services/app.service';
import {Router} from '@angular/router';
import { MedicalHistoryInterface} from '../../interfaces/patient.interface';
import {defaultMedicalHistory} from '../../services/mock-data';
import {PatientService} from '../../services/patient.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {SelectionService} from '../../services';
import {NavigationEventInterface} from '../../interfaces/app.interface';

@Component({
  selector: 'ep-medical-history-page',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicalHistoryPageComponent implements OnInit, OnDestroy {
  public medicalHistory: MedicalHistoryInterface;
  public formGroup: FormGroup;
  public now: Date;
  public surgeryStartsDate: Date;
  private footerNavSubscriber: Subscription;
  private getMedicalHistorySubscriber: Subscription;

  constructor(
    private appService: AppService,
    private router: Router,
    private patientService: PatientService,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private snackBar: MatSnackBar
  ) {
    this.medicalHistory = defaultMedicalHistory;
    this.now = new Date();
    this.surgeryStartsDate = new Date('02/02/1992');
  }

  ngOnInit() {
    this.getMedicalHistory();
    this.footerNavigationSubscribe();
  }

  ngOnDestroy() {
    this.footerNavSubscriber.unsubscribe();
  }

  public addSurgery() {
    if (this.isListFormFilled(this.medicalHistory.surgeriesLists)) {
      this.medicalHistory.surgeriesLists.push({
        id: 0,
        name: '',
        date: null
      });
    } else {
      this.openSnackBar('Please fill all existing surgeries');
    }
  }

  public addInjury() {
    if (this.isListFormFilled(this.medicalHistory.injuriesLists)) {
      this.medicalHistory.injuriesLists.push({
        id: 0,
        name: '',
        date: null
      });
    } else {
      this.openSnackBar('Please fill all existing injuries');
    }
  }

  public onDateChange(date, itemLink) {
    itemLink.date = date.getTime();
  }

  public lastExamDateChange(date) {
    this.medicalHistory.lastMedicalExamDate = date;
  }

  public saveMedicalHistory(navigationCb: Function) {
    this.appService.showSpinner();

    this.patientService.saveMedicalHistory(this.prepareDataToSave())
      .subscribe((result) => {
        this.appService.hideSpinner();

        if (result) {
          navigationCb();
        }
      });
  }

  private isListFormFilled(list) {
    for (const item of list) {
      if (!item.name) {
        return false;
      }
    }

    return true;
  }

  private getMedicalHistory() {
    this.appService.showSpinner();

    this.getMedicalHistorySubscriber = this.patientService.getMedicalHistory()
      .subscribe((history) => {
        this.ref.markForCheck();

        this.appService.hideSpinner();

        this.medicalHistory = history;
      });
  }

  private footerNavigationSubscribe() {
    this.footerNavSubscriber = this.appService.footerNavigation$
      .subscribe((navigationResult: NavigationEventInterface) => {
        this.saveMedicalHistory(navigationResult.navCb);
      });
  }

  private openSnackBar(msg: string) {
    this.snackBar.open(msg, null, {
      duration: 4000
    });
  }

  private prepareDataToSave(): MedicalHistoryInterface {
    return {
      ...this.medicalHistory,
      allergies: this.medicalHistory.allergies ? 1 : 0,
      arthritis: this.medicalHistory.arthritis ? 1 : 0,
      autoImmune: this.medicalHistory.autoImmune ? 1 : 0,
      cancer: this.medicalHistory.cancer ? 1 : 0,
      diabetes: this.medicalHistory.diabetes ? 1 : 0,
      headaches: this.medicalHistory.headaches ? 1 : 0,
      heartDisease: this.medicalHistory.heartDisease ? 1 : 0,
      hepatitis: this.medicalHistory.hepatitis ? 1 : 0,
      highCholesterol: this.medicalHistory.highCholesterol ? 1 : 0,
      hiv: this.medicalHistory.hiv ? 1 : 0,
      hypertension: this.medicalHistory.hypertension ? 1 : 0,
      lastMedicalExamDate: new Date(this.medicalHistory.lastMedicalExamDate).getTime(),
      nursingPatient: this.medicalHistory.nursingPatient ? 1 : 0,
      pregnantPatient: this.medicalHistory.pregnantPatient ? 1 : 0,
      thyroidDisease: this.medicalHistory.thyroidDisease ? 1 : 0,
      venerialDisease: this.medicalHistory.venerialDisease ? 1 : 0
    };
  }
}
