import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewContainerRef} from '@angular/core';
import {ThemesService} from './services/themes.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ROUTE_TYPE_APP_BLOCK, ROUTE_TYPE_AUTH} from './config/constants';
import {AppService} from './services';
import {Subscription} from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import {MatSnackBar} from '@angular/material';
import {NotificationService} from './services/notification.service';
import {ListSnackbarComponent} from './shared/components/list-snackbar/list-snackbar.component';

@Component({
  selector: 'ep-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  public isLoginPage: boolean;
  public title: string;
  public pageType: string;
  public formPageIndex: number;
  public isSpinnerShown: boolean;
  private spinnerSubscription: Subscription;

  constructor(
    public viewContainerRef: ViewContainerRef,
    public themesService: ThemesService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private snackBar: MatSnackBar,
    private notificationService: NotificationService
  ) {
    this.isSpinnerShown = false;
  }

  ngOnInit() {
    this.themesService.onThemeChanging.subscribe((value) => {
      this.setClassName(value);
    });

    this.routerSubscribe();
    this.subscribeSpinnerStatus();
    this.notificationSubscribe();
  }
  setClassName(className): void {
    this.viewContainerRef.element.nativeElement.parentElement.className = className;
  }

  private routerSubscribe() {
    this.router.events
      .filter((val) => val instanceof NavigationEnd)
      .subscribe((routerResult: NavigationEnd) => {
        const data = this.route.snapshot.root.firstChild.data;

        this.ref.markForCheck();

        this.title = data.title;
        this.pageType = data.type;
        this.isLoginPage = this.pageType === ROUTE_TYPE_AUTH || this.pageType === ROUTE_TYPE_APP_BLOCK;
        this.formPageIndex = data.formPageIndex;
      });
  }

  private subscribeSpinnerStatus() {
    this.spinnerSubscription = this.appService.spinnerStatusChanged
      .subscribe((status: boolean) => {
        this.ref.markForCheck();

        this.isSpinnerShown = status;
      });
  }

  private openSnackBar(msg: string): void {
    this.snackBar.open(msg, null, {
      duration: 4000
    });
  }

  private openListSnackBar(listMsg: string[]): void {
    this.snackBar.openFromComponent(ListSnackbarComponent, {
      duration: 4000,
      data: listMsg
    });
  }

  private notificationSubscribe() {
    this.notificationService.notificationEvent
      .subscribe((msg: string) => {
        this.appService.hideSpinner();
        this.openSnackBar(msg);
      });
    this.notificationService.notificationListEvent
      .subscribe((listMsg: string[]) => {
        this.appService.hideSpinner();
        this.openListSnackBar(listMsg);
      });
  }
}
