import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {UserInterface} from '../interfaces/user.interface';
import {select, Store} from '@ngrx/store';
import {AppStateInterface} from '../interfaces/store.interface';
import {ApiService} from './api.service';
import apiConstants from './api.constants';
import {catchError} from 'rxjs/operators';
import {NotificationService} from './notification.service';

@Injectable()
export class AuthService {
  private _isAdmin: boolean;
  private _isPatientSet: boolean;
  private _isAppBlocked: boolean;
  private user$: Observable<UserInterface>;
  private _currentUser: UserInterface;

  get isAdmin(): boolean {
    return this._isAdmin;
  }
  set isAdmin(value: boolean) {
    this._isAdmin = value;
  }
  get isPatientSet(): boolean {
    return this._isPatientSet;
  }
  set isPatientSet(value: boolean) {
    this._isPatientSet = value;
  }
  get isAppBlocked(): boolean {
    return this._isAppBlocked;
  }
  set isAppBlocked(value: boolean) {
    this._isAppBlocked = value;
  }

  get user(): UserInterface {
    return this._currentUser;
  }

  constructor(
    private store: Store<AppStateInterface>,
    private apiService: ApiService,
    private notificationService: NotificationService
  ) {
    this._isAdmin = false;
    this._isPatientSet = false;
    this._isAppBlocked = false;

    this.user$ = this.store.pipe(select('user'));
    this.userSubscribe();
  }

  public verifyManagerCode(code: string): Observable<boolean> {
    const params = {
      code,
      userId: this._currentUser.userId
    };

    return this.apiService.get(apiConstants.GET_VERIFY_MANAGER_CODE, params)
      .pipe(
        catchError((err, caught) => {
          this.notificationService.show(err);

          return Observable.throw(err);
        })
      );
  }

  private userSubscribe() {
    this.user$.subscribe((user) => {
      this._currentUser = user;
    });
  }

}
