import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {NAV_NEXT, NAV_PREV} from '../../config/constants';
import {AppService} from '../../services/app.service';
import {Subscription} from 'rxjs/Subscription';
import {PatientService} from '../../services/patient.service';
import {MedicationAndAllergyDTO, MedicationAndAllergyInterface, MedicationsAndAllergiesInterface} from '../../interfaces/patient.interface';
import {defaultAllergies} from '../../services/mock-data';
import {NavigationEventInterface} from '../../interfaces/app.interface';

@Component({
  selector: 'ep-medications-allergies-page',
  templateUrl: './medications-allergies.component.html',
  styleUrls: ['./medications-allergies.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicationsAllergiesPageComponent implements OnInit, OnDestroy {

  private footerNavSubscriber: Subscription;
  public entity: MedicationsAndAllergiesInterface = defaultAllergies;
  public allergies: MedicationAndAllergyInterface[] = [];
  public filteredAllergies: MedicationAndAllergyInterface[] = [];

  constructor(
    private appService: AppService,
    private patientService: PatientService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.footerNavigationSubscribe();
    this.getAllergies();
  }

  ngOnDestroy() {
    this.footerNavSubscriber.unsubscribe();
  }


  private getAllergies() {
    this.appService.showSpinner();

    this.patientService.getAllergiesInfo()
      .subscribe((allergies) => {
        this.appService.hideSpinner();

        if (allergies && allergies.medicationAndAllergies) {
          this.appService.oldAllergies = allergies.medicationAndAllergies.map(allergy => allergy.id);

          this.allergies = allergies.medicationAndAllergies.map(allergy => {
            return {
              ...allergy,
              isExisting: this.appService.oldAllergies.includes(allergy.id)
            } as MedicationAndAllergyInterface;
          });
          this.entity = allergies;
          this.ref.markForCheck();
        }

        this.allergies = [
          ...this.allergies.filter(all => !all.isExisting),
          ...this.allergies.filter(all => all.isExisting)
        ];

        this.filteredAllergies = [...this.allergies];
      });
  }

  private saveData(navigationCb) {
    this.appService.showSpinner();

    const medications: MedicationAndAllergyDTO[] = [];
    for (const entity of this.allergies){
      medications.unshift({
        id: entity.id,
        startDate: entity.startDate ? entity.startDate.valueOf() : 0,
        stopDate: entity.stopDate ? entity.stopDate.valueOf() : 0,
        reaction: entity.reaction,
        current: entity.current ? 1 : 0,
        name: entity.name
      });
    }
    this.patientService.saveAllergies({
      ...this.entity,
      noActiveAllergies: this.entity.noActiveAllergies ? 1 : 0,
      medicationAndAllergies: medications
    })
      .subscribe(
        (result) => {
          if (result) {
            navigationCb();
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  public addAllergie() {
    this.ref.markForCheck();

    this.allergies.unshift({
      id: 0,
      name : '',
      current : false,
      startDate : new Date(),
      stopDate: null,
      reaction : '',
      isExisting: false
    });

    this.allergies = [...this.allergies];
    this.switchNoActive();
  }

  public switchNoActive() {
    this.ref.markForCheck();

    this.entity.noActiveAllergies = 0;
    this.filteredAllergies = [...this.allergies];
  }

  private footerNavigationSubscribe() {
    this.footerNavSubscriber = this.appService.footerNavigation$
      .subscribe((navigationResult: NavigationEventInterface) => {
        this.saveData(navigationResult.navCb);
      });
  }

}
