import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {NAV_NEXT, NAV_PREV} from '../../config/constants';
import {Router} from '@angular/router';
import {AppService} from '../../services/app.service';
import {Subscription} from 'rxjs/Subscription';
import {PatientService} from '../../services/patient.service';
import {CustomHistoryFormInterface} from '../../interfaces/patient.interface';
import {NavigationEventInterface} from '../../interfaces/app.interface';
import {MatDialog} from '@angular/material';
import {ExtraTextEditComponent} from './extra-text-edit/extra-text-edit.component';

@Component({
  selector: 'ep-additional-history',
  templateUrl: './additional-history.component.html',
  styleUrls: ['./additional-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalHistoryComponent implements OnInit, OnDestroy {
  private footerNavSubscriber: Subscription;
  public customHistory: CustomHistoryFormInterface[] = [];

  constructor(
    private appService: AppService,
    private router: Router,
    private patientService: PatientService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.getCustomHistory();
  }

  ngOnInit() {
    this.footerNavigationSubscribe();
  }

  ngOnDestroy() {
    this.footerNavSubscriber.unsubscribe();
  }

  public radioClick(value: string, form) {
    if (value === form.value) {
      setTimeout(() => {
        this.ref.markForCheck();

        form.value = '';
        this.customHistory = [...this.customHistory];
      }, 50);
    }
  }

  public openTextEditDialog(form) {
    const dialog = this.dialog.open(ExtraTextEditComponent, {
      data: {
        text: form.value
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        form.value = result;
      }
    });
  }

  private getCustomHistory() {
    this.appService.showSpinner();

    this.patientService.getCustomHistory()
      .subscribe((history) => {
        this.appService.hideSpinner();

        if (history) {
          this.customHistory = history;
          this.ref.markForCheck();
        }
      });
  }

  private saveData(navigationCb) {
    const storingData = {};
    this.customHistory.forEach(cs => storingData[cs.id] = cs.value);

    this.patientService.saveCustomHistory(storingData)
      .subscribe(
        result => {
          if (result) {
            navigationCb();
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  private footerNavigationSubscribe() {
    this.footerNavSubscriber = this.appService.footerNavigation$
      .subscribe((navigationResult: NavigationEventInterface) => {
        this.saveData(navigationResult.navCb);
      });
  }

}
