import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input} from '@angular/core';
import {NAV_NEXT, NAV_PREV, ROUTE_TYPE_FORM, ROUTE_TYPE_PATIENT_CHECK} from '../../config/constants';
import {AppService} from '../../services/app.service';
import { routes } from '../../app-routing';
import {Router} from '@angular/router';
import {PatientService} from '../../services';

interface PageMenuInterface {
  pageIndex: number;
  path: string;
  title: string;
}

@Component({
  selector: 'ep-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  public formPageName: string;
  public patientCheckPageName: string;
  public menuList: PageMenuInterface[];

  @Input()
  public pageType: string;
  @Input()
  public formPageIndex: number;

  constructor(
    private appService: AppService,
    private router: Router,
    private patientService: PatientService
  ) {
    this.menuList = [];
  }

  ngOnInit() {
    this.formPageName = ROUTE_TYPE_FORM;
    this.patientCheckPageName = ROUTE_TYPE_PATIENT_CHECK;

    this.setPageMenu();
  }

  public goPrev() {
    const destPageIndex = this.formPageIndex - 1;
    const path = this.getDestinationPath(destPageIndex);
    const navigationCb = () => {
      if (path === 'demographics') {
        this.router.navigate([path, {isBack: true}]);
      } else {
        this.router.navigate([path]);
      }
    };

    this.appService.footerNavigate(navigationCb.bind(this), "prev");
  }

  public goNext() {
    const destPageIndex = this.formPageIndex + 1;
    const path = this.getDestinationPath(destPageIndex);
    const navigationCb = () => {
      this.router.navigate([path]);
    };

    this.appService.footerNavigate(navigationCb.bind(this), "next");
  }

  public refresh() {
    this.appService.updateRequired.emit();
  }

  public finish() {
    const navigationCb = () => {
      this.router.navigate(['app-block-login']);
    };

    this.appService.footerNavigate(navigationCb.bind(this), "finish");
  }

  public navigateToPage(path: string) {
    const navigationCb = () => {
      if (path === 'demographics') {
        this.router.navigate([path, {isBack: true}]);
      } else {
        this.router.navigate([path]);
      }
    };

    this.appService.footerNavigate(navigationCb.bind(this), "to-page");
  }

  private getDestinationPath(pageIndex: number) {
    return this.menuList.find((page) => page.pageIndex === pageIndex).path;
  }

  private setPageMenu(): void {
    const filteredRoutes = routes.filter((route) => route.data && route.data.type === ROUTE_TYPE_FORM);

    this.menuList = filteredRoutes.map((route): PageMenuInterface => {
      return {
        path: route.path,
        title: route.data.title,
        pageIndex: route.data.formPageIndex
      };
    });
  }
}
