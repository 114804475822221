import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {NAV_NEXT, NAV_PREV} from '../../config/constants';
import {Router} from '@angular/router';
import {AppService} from '../../services/app.service';
import {Subscription} from 'rxjs/Subscription';
import {SelectOptionInterface} from '../../interfaces/selectOptions.interface';
import {HobbyInterface, SocialHistoryInterface} from '../../interfaces/patient.interface';
import {defaultSocialHistory} from '../../services/mock-data';
import {SelectionService} from '../../services/selection.service';
import {PatientService} from '../../services/patient.service';
import {NavigationEventInterface} from '../../interfaces/app.interface';

@Component({
  selector: 'ep-social-history',
  templateUrl: './social-history.component.html',
  styleUrls: ['./social-history.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialHistoryComponent implements OnInit, OnDestroy {
  public hobbiesList: SelectOptionInterface[];
  public socialHistory: SocialHistoryInterface;
  public selectedHobbiesValues: string[];
  private footerNavSubscriber: Subscription;

  constructor(
    private appService: AppService,
    private router: Router,
    public selectionService: SelectionService,
    private patientService: PatientService,
    private ref: ChangeDetectorRef
  ) {
    this.socialHistory = {...defaultSocialHistory};
  }

  ngOnInit() {
    this.footerNavigationSubscribe();
    this.getSocialHistory();
    this.getHobbiesList();
  }

  ngOnDestroy() {
    this.footerNavSubscriber.unsubscribe();
  }

  public hobbiesChange() {
    this.selectedHobbiesValues = [];

    // In case when no hobbies we get from the serve 1 item with ID -1
    if (this.socialHistory.hobbies.length === 1 && this.socialHistory.hobbies[0] === -1) {
      return;
    }

    this.socialHistory.hobbiesLists = this.socialHistory.hobbies
      .map((hobbyId: number) => {
        const hobby = this.getHobbyById(hobbyId);
        this.selectedHobbiesValues.push(hobby.viewValue);

        return {
          categoryId: this.socialHistory.categoryId,
          historyId: this.socialHistory.historyId,
          name: this.socialHistory.name,
          id: hobbyId.toString(),
          value: hobby.viewValue
        };
      });
  }

  private getHobbiesList() {
    this.appService.showSpinner();

    this.selectionService.hobbiesList$.subscribe((list) => {
      this.appService.hideSpinner();

      this.ref.markForCheck();

      this.hobbiesList = list;

      this.hobbiesChange();
    });
  }

  private footerNavigationSubscribe() {
    this.footerNavSubscriber = this.appService.footerNavigation$
      .subscribe((navigationResult: NavigationEventInterface) => {
        this.saveHistory(navigationResult.navCb);
      });
  }

  private getSocialHistory() {
    this.appService.showSpinner();

    this.patientService.getSocialHistory()
      .subscribe(
        (history) => {
          this.ref.markForCheck();

          this.socialHistory = history;

          this.hobbiesChange();
        },
        err => {
          console.log(err);
        }
      );
  }

  private saveHistory(navigationCb: Function) {
    this.appService.showSpinner();

    const preparedHistory = {
      ...this.socialHistory,
      drivesVehical: this.socialHistory.drivesVehical ? 1 : 0,
      drivingDificulties: this.socialHistory.drivingDificulties ? 1 : 0
    };

    this.patientService.saveSocialHistory(preparedHistory)
      .subscribe(
        (result) => {
          this.appService.hideSpinner();
          if (result) {
            navigationCb();
          }
        },
        err => {
          this.appService.hideSpinner();
        }
      );
  }

  private getHobbyById(id: number): SelectOptionInterface {
    return this.hobbiesList.find((hobby) => hobby.value === id);
  }
}
