import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ConsentAndSignaturePageComponent} from './consent.component';
import {MatButtonModule, MatCardModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatSnackBarModule} from '@angular/material';
import {SharedModule} from '../../shared/shared.module';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    MatInputModule,
    FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSnackBarModule
  ],
  declarations: [ConsentAndSignaturePageComponent]
})
export class ConsentAndSignatureModule { }
