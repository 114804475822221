export const FAMILY_MEMBER_LIST = [
  {
    id: 'aunt',
    name: 'Aunt'
  },
  {
    id: 'brother',
    name: 'Brother'
  },
  {
    id: 'child',
    name: 'Child'
  },
  {
    id: 'father',
    name: 'Father'
  },
  {
    id: 'grandFather',
    name: 'Grandfather'
  },
  {
    id: 'grandMother',
    name: 'Grandmother'
  },
  {
    id: 'mother',
    name: 'Mother'
  },
  {
    id: 'sister',
    name: 'Sister'
  },
  {
    id: 'uncle',
    name: 'Uncle'
  },
];
