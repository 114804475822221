import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {PatientService} from '../../services/patient.service';
import {Router} from '@angular/router';
import {AppStateInterface} from '../../interfaces/store.interface';
import {Observable} from 'rxjs/Observable';
import {select, Store} from '@ngrx/store';
import {PatientInterface} from '../../interfaces/patient.interface';
import {AuthService} from '../../services/auth.service';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'ep-app-block',
  templateUrl: './app-block.component.html',
  styleUrls: ['./app-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppBlockComponent implements OnInit, OnDestroy {
  public isFilled: boolean;
  public patient$: Observable<PatientInterface>;

  constructor(
    private patientService: PatientService,
    private router: Router,
    private store: Store<AppStateInterface>,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {
    this.isFilled = false;
    this.patient$ = store.pipe(select('patient'));
  }

  ngOnInit() {
    this.authService.isPatientSet = false;
    this.isFilled = this.patientService.isFilled;
    this.patientService.clearStorageData('consent'); // Clear consent form data and signature
  }

  ngOnDestroy() {
    this.patientService.isFilled = false;
  }

  public unlockApp(code: string) {
    this.authService.verifyManagerCode(code)
      .subscribe((result) => {
        if (result) {
          this.authService.isAdmin = true;
          this.authService.isAppBlocked = false;
          this.router.navigate(['patient-check']);
        } else {
          this.wrongCodeSnackbar();
        }
      });
  }

  public wrongCodeSnackbar() {
    this.snackBar.open('Wrong code', null, {
      duration: 4000
    });
  }
}
