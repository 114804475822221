import { Injectable } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PatientDemographicsInterface} from '../../interfaces/patient.interface';
import * as moment from 'moment';
import {SelectionService} from '../../services';
import {SelectOptionInterface} from '../../interfaces/selectOptions.interface';
import {Observable} from 'rxjs/Observable';
import { map, catchError } from 'rxjs/operators';

const errorMsgMap = {
  firstName: 'Fill First Name field',
  lastName: 'Fill Last Name field',
  street1: 'Fill Address field',
  zip: 'Fill ZIP field. It should be 5 numbers',
  city: 'Select City',
  state: 'Select State',
  male: 'Select Gender'
};

@Injectable()
export class DemographicsService {
  public stateList: SelectOptionInterface[];
  private _cityList: SelectOptionInterface[];
  public filteredStates$: Observable<SelectOptionInterface[]>;
  public filteredCities$: Observable<SelectOptionInterface[]>;
  public errorMessages;
  private _form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private selectionService: SelectionService
  ) {
    this.selectionService.getSelections();

    this.stateList = [];
    this._cityList = [];
    this.errorMessages = errorMsgMap;
    this.getStateList();
  }

  get controls() {
    return this._form.controls;
  }

  get stateControl() {
    return this._form.get('state');
  }

  get cityControl() {
    return this._form.get('city');
  }

  set cityList(list) {
    this._cityList = list;
  }

  get cityList() {
    return this._cityList;
  }

  public getConstructedForm() {
    this._form = this.fb.group({
      prefixId: this.fb.control(''),
      firstName: this.fb.control('', Validators.required),
      lastName: this.fb.control('', Validators.required),
      cellPhone: this.fb.control(''),
      homePhone: this.fb.control(''),
      workPhone: this.fb.control(''),
      emailAddress: this.fb.control(''),
      prefCommunicationId: this.fb.control(''),
      street1: this.fb.control('', Validators.required),
      street2: this.fb.control(''),
      zip: this.fb.control('', Validators.required),
      city: this.fb.control('', Validators.required),
      state: this.fb.control(''),
      SSN: this.fb.control(''),
      referraLID: this.fb.control(''),
      preferredLanguageID: this.fb.control(''),
      epmloymentStatusId: this.fb.control(''),
      employerName: this.fb.control(''),
      raceID: this.fb.control(''),
      ethnicityId: this.fb.control(''),
      male: this.fb.control('', Validators.required),
    });


    this.filteredStates$ = this.stateControl.valueChanges
      .pipe(
        map((value = '') => {
          return this.stateList.filter((state: SelectOptionInterface) => {
            return state.viewValue.toUpperCase().includes(value.toString().toUpperCase());
          });
        }),
        catchError((err) => {
          return Observable.of([]);
        })
      );
    this.filteredCities$ = this.cityControl.valueChanges
      .pipe(
        map((value = '') => {
          return this.cityList.filter((city: SelectOptionInterface) => {
            return city.viewValue.toUpperCase().includes(value.toString().toUpperCase());
          });
        }),
        catchError((err) => {
          return Observable.of([]);
        })
      );

    return this._form;
  }

  public getStateNameById(id: number) {
    const state = this.stateList.find((stateItem) => stateItem.value === id);

    return state ? state.viewValue : '';
  }

  public updateForm(patient: PatientDemographicsInterface) {
    this.controls.prefixId.setValue(patient.prefixId);
    this.controls.firstName.setValue(patient.firstName);
    this.controls.lastName.setValue(patient.lastName);
    this.controls.cellPhone.setValue(patient.cellPhone);
    this.controls.homePhone.setValue(patient.homePhone);
    this.controls.workPhone.setValue(patient.workPhone);
    this.controls.emailAddress.setValue(patient.emailAddress);
    this.controls.prefCommunicationId.setValue(patient.prefCommunicationId);
    this.controls.street1.setValue(patient.street1);
    this.controls.street2.setValue(patient.street2);
    this.controls.zip.setValue(patient.zip);
    this.controls.city.setValue(patient.city);
    this.controls.state.setValue(this.getStateNameById(patient.state));
    this.controls.SSN.setValue(patient.SSN);
    this.controls.referraLID.setValue(patient.referraLID);
    this.controls.preferredLanguageID.setValue(patient.preferredLanguageID);
    this.controls.epmloymentStatusId.setValue(patient.epmloymentStatusId);
    this.controls.employerName.setValue(patient.employerName);
    this.controls.raceID.setValue(patient.raceID);
    this.controls.ethnicityId.setValue(patient.ethnicityId);
    this.controls.male.setValue(patient.male);
  }

  public getFormErrorMessages(patient: PatientDemographicsInterface): string[] {
    const dateOfBirthMsg = 'Fill Birthday field';
    const msgList = [];

    for (const [controlName, msg] of Object.entries(errorMsgMap)) {
      if (this._form.controls[controlName].invalid) {
        msgList.push(msg);
      }
    }

    if (!patient.dateOfBirth) {
      msgList.push(dateOfBirthMsg);
    }

    return msgList;
  }

  private getStateList() {
    this.selectionService.stateList$.subscribe(
      (list) => {
        this.stateList = list;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
