import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ElementRef, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {Router} from '@angular/router';
import {AppService} from '../../services/app.service';
import {NAV_PREV, NAV_NEXT} from '../../config/constants';
import {PatientService} from '../../services/patient.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {MatSnackBar} from '@angular/material';
import {ConsentDtoInterface} from '../../interfaces/patient.interface';
import {NavigationEventInterface} from '../../interfaces/app.interface';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'ep-consent-page',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsentAndSignaturePageComponent implements OnInit, OnDestroy {
  private footerNavSubscriber: Subscription;
  private prevX = -1;
  private prevY = -1;
  private canvas: HTMLCanvasElement;
  public entity = {
    isGuardian : false,
    isPatient : true,
    guardianName : '',
  };
  private points = [];
  public forms;
  public docSrc = '';
  public docShown = false;
  public currentForm = null;

  public get isGuardian(){
    return this.entity.isGuardian;
  }
  public set isGuardian(value){
    this.entity.isGuardian = value;
    this.entity.isPatient = !value;
  }

  public get isPatient(){
    return this.entity.isPatient;
  }
  public set isPatient(value){
    this.entity.isGuardian = !value;
    this.entity.isPatient = value;
  }


  constructor(
    private appService: AppService,
    private router: Router,
    private nativeEl: ElementRef,
    private patientService: PatientService,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private notificationService: NotificationService
  ) {
    this.patientService.isFilled = false;
  }

  ngOnInit()    {
    this.appService.showSpinner();

    if (this.patientService.hasData('consent')) {
      const obj = this.patientService.getData('consent');
      setTimeout(() => {
        this.points = obj.points;
        this.entity = obj.entity;
        this.forms = obj.forms;
        this.appService.hideSpinner();
        this.ref.markForCheck();
        this.reDraw();
      }, 1000);
    } else {
      this.patientService.getConsentForms().subscribe((data) => {
        this.appService.hideSpinner();
        this.forms = data;
        this.forms.forEach((form) => form.agree = null);
        this.ref.markForCheck();
      });
    }
    this.footerNavigationSubscribe();

    let blockMenuHeaderScroll = false;

    this.canvas = document.getElementById('signaturePad') as HTMLCanvasElement;
    this.canvas.addEventListener('touchstart', (event) => {
      const rect = this.canvas.getBoundingClientRect();
      if (event.touches.length) {
        blockMenuHeaderScroll = true;
        this.startDraw(event.touches[0].clientX - rect.left, event.touches[0].clientY - rect.top);
      }
    });
    this.canvas.addEventListener('touchmove', (event) => {
      const rect = this.canvas.getBoundingClientRect();
      if (event.touches.length) {
        this.draw(event.touches[0].clientX - rect.left, event.touches[0].clientY - rect.top);
      }
      event.preventDefault();
    });
    this.canvas.addEventListener('touchend', (event) => {
      const rect = this.canvas.getBoundingClientRect();
      blockMenuHeaderScroll = false;
      if (event.touches.length) {
        this.endDraw(event.touches[0].clientX - rect.left, event.touches[0].clientY - rect.top);
      }
    });

    window.addEventListener('touchmove', function(e)
    {
      if (blockMenuHeaderScroll) {
        e.stopPropagation();
      }
    });

    this.canvas.addEventListener('mousedown', (event) => {
      if (event.buttons) {
        this.startDraw(event.offsetX, event.offsetY);
      }
    });
    this.canvas.addEventListener('mouseup', (event) => {
      if (event.buttons) {
        this.endDraw(event.offsetX, event.offsetY);
      }
    });
    this.canvas.addEventListener('mousemove', (event) => {
      if (event.buttons) {
        this.draw(event.offsetX, event.offsetY);
      }
    });
    const container = this.nativeEl.nativeElement.querySelector('#signatureContainer');
    this.canvas.width = container.clientWidth;
    window.addEventListener('resize', (event) => {
      this.canvas.width = container.clientWidth;
      this.reDraw();
    });
  }

  ngOnDestroy() {
    this.footerNavSubscriber.unsubscribe();
  }

  showDoc(tmpVal) {
    this.ref.markForCheck();

    this.currentForm = tmpVal;
    this.docSrc = tmpVal.formUrl;
    this.docShown = true;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  agreeDoc(value) {
    this.docShown = false;
    this.currentForm.agree = value;
  }

  clearSignature() {
    this.prevX = -1;
    this.prevY = -1;
    this.points = [];
    const ctx  = this.canvas.getContext('2d');
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  startDraw(x, y) {
    const ctx  = this.canvas.getContext('2d');
    this.prevX = x;
    this.prevY = y;
    this.points.push({x: x, y: y, type: 'start'});
  }

  endDraw(x, y) {
    this.prevX = -1;
    this.prevY = -1;
  }

  draw(x, y) {
    const ctx  = this.canvas.getContext('2d');
    ctx.strokeStyle = 'blue';
    ctx.globalCompositeOperation = 'screen';
    ctx.lineWidth = 5;
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    if (this.prevX < 0 && this.prevY < 0) {
      this.prevX = x;
      this.prevY = y;
    }
    ctx.beginPath();
    ctx.moveTo(this.prevX, this.prevY);
    ctx.lineTo(x, y);
    ctx.stroke();
    this.points.push({x: x, y: y, type: 'draw'});
    this.prevX = x;
    this.prevY = y;
  }

  reDraw() {
    const ctx  = this.canvas.getContext('2d');
    ctx.strokeStyle = 'blue';
    ctx.globalCompositeOperation = 'screen';
    ctx.lineWidth = 5;
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    ctx.beginPath();
    this.points.forEach((point) => {
      if (point.type === 'start') {
        ctx.moveTo(point.x, point.y);
      }
      if (point.type === 'draw') {
        ctx.lineTo(point.x, point.y);
      }
    });
    ctx.stroke();
  }

  public trySave(navigationCB: Function, navigationType: string) {
    const self = this;
    const result = self.forms ? self.forms.reduce(function(acc, form){ return acc && (form.agree || !form.patientAgree); }, true) : false;

    if (navigationType !== 'prev' && navigationType !== 'to-page') {
      const isCorrectFilling = this.checkCorrectFormFilling(result);

      if (isCorrectFilling) {
        const data: ConsentDtoInterface = {
          patientSign : this.entity.isPatient,
          guardianSign: this.entity.isGuardian,
          guardianName: this.entity.guardianName,
          image: this.canvas.toDataURL(),
          consentFormDtos: []
        };
        this.forms.forEach((form) => { data.consentFormDtos.push(form); });

        this.appService.spinnerStatusChanged.emit(true);
        this.patientService.saveContestData(data).subscribe((res) => {

          this.appService.resetOldLists();
          this.appService.spinnerStatusChanged.emit(false);
          this.patientService.isFilled = true;
          navigationCB();
        });
      }
    } else {
      navigationCB();
    }
  }

  private checkCorrectFormFilling(formsResult) {
    const errorMessages = [];

    if (!formsResult) {
      errorMessages.push('Please read and accept all consent forms.');
    }
    if (!this.points.length) {
      errorMessages.push('Please sign in the signature box');
    }
    if (!(this.entity.isPatient || this.entity.isGuardian)) {
      errorMessages.push('Please select signing person ');
    }
    if (this.entity.isGuardian && !this.entity.guardianName) {
      errorMessages.push('Please fill the guardian name');
    }

    if (errorMessages.length) {
      this.notificationService.showList(errorMessages);
      return false;
    }

    return true;
  }

  private footerNavigationSubscribe() {
    // const self = this;
    // this.appService.onFinish = () => {
    //   return self.trySave();
    // };
    this.footerNavSubscriber = this.appService.footerNavigation$
      .subscribe((navigationResult: NavigationEventInterface) => {
        this.patientService.setData('consent', {
          points: this.points,
          entity: this.entity,
          forms: this.forms
        });
        this.trySave(navigationResult.navCb , navigationResult.type);
      });
  }

}
