/**
 * Created by Alexander on 11-Aug-17.
 */
import {Injectable, Output} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ThemesService {
  static currentTheme = 'app-default-theme';

  get theme(): string{
    return ThemesService.currentTheme;
  }

  @Output() onThemeChanging: EventEmitter<string> = new EventEmitter();

  constructor(

  ) {

  }

  public switchTheme(newTheme): void {
    ThemesService.currentTheme = newTheme;
    this.onThemeChanging.emit(newTheme);
  };

}
