export default {
  eyepegagusSelectionApiList: [
    {
      id: 'languageList$',
      apiKey: 'com.eyepegasus.demographics.language.type'
    },
    {
      id: 'raceList$',
      apiKey: 'com.eyepegasus.demographics.race.type'
    },
    {
      id: 'ethnicityList$',
      apiKey: 'com.eyepegasus.demographics.ethnicity.type'
    }
  ],
  socialHistorySelectionApiList: [
    {
      id: 'hobbiesList$',
      apiKey: 'com.eyepegasus.medical.history.social.history.hobbies'
    },
    {
      id: 'maritalStatusList$',
      apiKey: 'com.eyepegasus.demographics.marital.status.type'
    },
    {
      id: 'socialHistoryOccupationList$',
      apiKey: 'com.eyepegasus.demographics.occupation.type'
    }
  ],
  socialHistorySelectionInStringsApiList: [
    {
      id: 'livingArrangementList$',
      apiKey: 'com.eyepegasus.medical.history.social.history.living.arrangement'
    },
    {
      id: 'alcoholUseList$',
      apiKey: 'com.eyepegasus.medical.history.social.history.alcohol.use'
    },
    {
      id: 'computerUseList$',
      apiKey: 'com.eyepegasus.social.history.computer.use.per.day'
    },
    {
      id: 'narcoticsUseList$',
      apiKey: 'com.eyepegasus.medical.history.social.history.narcotics.use'
    }
  ],
  masterSelectionApiList: [
    {
      id: 'preferredCommunicationList$',
      apiKey: 'com.eyepegasus.preferred.communication.type'
    },
    {
      id: 'employmentStatusList$',
      apiKey: 'com.eyepegasus.demographics.employement.status.type'
    },
    {
      id: 'demographicsOccupationList$',
      apiKey: 'com.eyepegasus.demographics.occupation.type'
    },
    {
      id: 'demographicsSourceList$',
      apiKey: 'com.eyepegasus.demographics.source.type'
    }
  ],
  patientPrefixSelection: [
    {
      value: 11014,
      viewValue: 'Dr.'
    },
    {
      value: 11015,
      viewValue: 'Mr.'
    },
    {
      value: 11016,
      viewValue: 'Mrs.'
    },
    {
      value: 11017,
      viewValue: 'Ms.'
    }
  ]
};
