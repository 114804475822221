import { Injectable } from '@angular/core';
import {AuthInterface} from '../interfaces/auth.interface';
import {UserInterface} from '../interfaces/user.interface';
import {AuthService} from './auth.service';
import {ApiService} from './api.service';
import apiConstants from './api.constants';
import {select, Store} from '@ngrx/store';
import {AppStateInterface} from '../interfaces/store.interface';
import {SET_USER} from '../store/user';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';

@Injectable()
export class LoginService {
  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private store: Store<AppStateInterface>
  ) {
  }

  public login(authData: AuthInterface): Observable<UserInterface> {
    const param = 'username=' + authData.username + '&password=' + authData.password + '&fromEp=false';
    const authStr = authData.username + ':' + authData.password;

    apiConstants.authToken = `Basic ${btoa(authStr)}`;

    return this.apiService.post(apiConstants.POST_LOGIN, param)
      .pipe(
        map((user) => {
          this.store.dispatch({type: SET_USER, payload: user});

          return user;
        })
      );
  }

}
