import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {PatientCheckinInterface} from '../../interfaces/patient.interface';
import {Router} from '@angular/router';
import {AuthService , PatientService, SelectionService, AppService} from '../../services';
import {AppStateInterface} from '../../interfaces/store.interface';
import {Observable} from 'rxjs/Observable';
import {select, Store} from '@ngrx/store';
import {UserInterface} from '../../interfaces/user.interface';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'ep-patient-check',
  templateUrl: './patient-check.component.html',
  styleUrls: ['./patient-check.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientCheckComponent implements OnInit, OnDestroy {
  public patientList: PatientCheckinInterface[];
  private user: UserInterface;
  private user$: Observable<UserInterface>;
  private userSubscription: Subscription;
  private refreshSubscription: Subscription;

  constructor(
    private router: Router,
    private appService: AppService,
    private authService: AuthService,
    private patientService: PatientService,
    private store: Store<AppStateInterface>,
    private ref: ChangeDetectorRef,
    private selectionService: SelectionService,
  ) {
    this.patientList = [];
    this.user$ = store.pipe(select('user'));
  }

  ngOnInit() {
    this.userSubscribe();

    this.getPatients();
    this.refreshSubscribe();
    this.selectionService.getSelections();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.refreshSubscription.unsubscribe();
  }

  public selectPatient(patient: PatientCheckinInterface): void {
    this.authService.isPatientSet = true;
    this.patientService.selectedPatient = patient;

    this.router.navigate(['demographics']);
  }

  public getPatients() {
    this.appService.showSpinner();

    this.patientService.getPatientList(this.user.organizationId)
      .subscribe((patientsList: PatientCheckinInterface[]) => {
        this.ref.markForCheck();

        this.appService.hideSpinner();

        this.patientList = patientsList;
      });
  }

  private userSubscribe() {
    this.userSubscription = this.user$.subscribe((user) => {
      this.user = user;
    });
  }

  private refreshSubscribe() {
    this.refreshSubscription = this.appService.updateRequired.subscribe(() => {
      this.getPatients();
    });
  }

}
